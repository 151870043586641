import React from 'react'
import Head from 'next/head'

import Script from 'next/script'
import type { DEV, LOCAL, STAGING, TICTUK_TESTS } from 'types/Env'
import { PROD } from 'types/Env'
import type { LanguageCode } from 'utils/language'
import OpenGraph from './OpenGraph'
import type { Graph } from './OpenGraph'

const THEME_COLOR = '#000000'
export const HEADER_KEYS = {
	appleMobileWebAppCapable: 'apple-mobile-web-app-capable',
	XUACompatible: 'X-UA-Compatible',
	robots: 'robots',
	themeColor: 'theme-color',
	favicon: 'favicon',
	viewport: 'viewport',
	description: 'description',
	googleSiteVerification: 'google-site-verification',
	facebookSiteVerification: 'facebook-site-verification',

	OGType: 'og-type',
	OGTitle: 'og-title',
	OGImage: 'og-image',
	OG_URL: 'og-url',
	OGLocale: 'og-locale',
	OGDescription: 'og-description',
	OGProductPriceCurrency: 'product-price-currency',
	OGProductPriceAmount: 'product-price-amount',
	OGProductBrand: 'product-brand',
	OGProductAvailability: 'product-availability',
	OGProductCondition: 'product-condition',
	OGProductCatalogId: 'product-catalog-id',
	OGProductRetailerItemId: 'product-retailer-item-id',

	twitterType: 'twitter-type',
	twitterTitle: 'twitter-title',
	twitterImage: 'twitter-image',
	twitterURL: 'twitter-url',
	twitterDescription: 'twitter-description',
	twitterLocale: 'twitter-locale',
	trackingByLuckyOrange: 'tracking-by-lucky-orange',
	title: 'title',
	canonicalLink: 'canonical-link',
}

interface Props {
	verificationTags?: {
		google?: string
		facebook?: string
	}
	favicon: string
	openGraph?: Graph
	trackingByLuckyOrange?: string
	currentPageURL?: string
	description: string
	title: string
	lang?: LanguageCode
	environment: typeof LOCAL | typeof DEV | typeof STAGING | typeof TICTUK_TESTS | typeof PROD
}

const CustomHead = ({
	verificationTags,
	favicon,
	openGraph,
	trackingByLuckyOrange,
	currentPageURL,
	description,
	title,
	lang,
	environment,
}: Props) => {
	// currentPageURL?.includes("test.") is added as a hotfix because or a market that went live with a test-production store
	const robotsNoIndex = environment !== PROD || currentPageURL?.includes('test.')

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta name="apple-mobile-web-app-capable" content="yes" key={HEADER_KEYS.appleMobileWebAppCapable} />
				{/* <meta httpEquiv="X-UA-Compatible" content="IE=Edge,chrome=1" key={HEADER_KEYS.XUACompatible} /> Removing this task for now, because It is needed for IE browsers which we should not support */}
				{robotsNoIndex && <meta name="robots" content="noindex,nofollow" key={HEADER_KEYS.robots} />}
				<meta name="theme-color" content={THEME_COLOR} key={HEADER_KEYS.themeColor} />
				<link rel="icon" id="favicon" href={favicon} key={HEADER_KEYS.favicon} />
				<link rel="apple-touch-icon" href={favicon} />
				<link href={favicon} />
				{currentPageURL && <link rel="canonical" href={currentPageURL} key={HEADER_KEYS.canonicalLink} />}
				<link rel="manifest" href="/manifest.json" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no"
					key={HEADER_KEYS.viewport}
				/>
				<meta name="description" content={description} key={HEADER_KEYS.description} />
				{verificationTags?.google && (
					<meta name="google-site-verification" content={verificationTags.google} key={HEADER_KEYS.googleSiteVerification} />
				)}
				{verificationTags?.facebook && (
					<meta name="facebook-site-verification" content={verificationTags.facebook} key={HEADER_KEYS.facebookSiteVerification} />
				)}
				<meta property="og:type" content="website" key={HEADER_KEYS.OGType} />
				<meta name="twitter:type" content="website" key={HEADER_KEYS.twitterType} />
				{openGraph && <OpenGraph graph={openGraph} currentPageURL={currentPageURL} headerKeys={HEADER_KEYS} />}
				{lang && (
					<>
						<meta property="og:locale" content={lang} key={HEADER_KEYS.OGLocale} />
						<meta name="twitter:locale" content={lang} key={HEADER_KEYS.twitterLocale} />
					</>
				)}
				<title key={HEADER_KEYS.title}>{title}</title>
			</Head>
			{trackingByLuckyOrange && <Script async defer src={trackingByLuckyOrange} key={HEADER_KEYS.trackingByLuckyOrange} />}
		</>
	)
}

export default CustomHead
